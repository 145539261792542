.date-range-filter-modal {
  width: 350px;
  padding: 10px;

  .date-pickers-container {
    display: flex;
    align-items: center;
    gap: 4px;

    .separator {
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }
}