.metrics-page {
  padding: 8px;
  margin: 32px 0px;

  .title {
    font-weight: 700;
    font-size: 28px;
  }

  .metric-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .metric-item {
    flex: 1;
    min-width: 0;
    margin: 32px;

    .metric-title {
        color: #008037;
    }

    .selector {
      margin: 20px 0px
    }
  }

  .filters-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 16px 0;
    height: 37px;

    .filters {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-left: 10px;

      .filter {
        position: relative;

        .buttons-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}