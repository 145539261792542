.sale-recurring-view {
  padding: 24px;

  .view-header {
    padding: 0 16px;
    margin-bottom: 16px;

    .view-title {
      width: fit-content;
      min-width: 100px;
      height: 24px;
    }
  }

  .details {
    padding: 0 16px;
    padding-top: 24px;

    .details-subtitle {
      margin-bottom: 16px;
      color: #008037;
    }

    .info-row {
      display: flex;
      margin-bottom: 24px;
    }

    .basic-info,
    .billing-info,
    .payment-info,
    .tax-info {
      margin-bottom: 40px;
    }
  }
}
