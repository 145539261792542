.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    margin-bottom: 28px;
    width: 200px;
    height: fit-content;

    img {
      width: 100%;
    }
  }

  .card {
    padding: 40px 24px;
    width: 420px;
    border: 1px solid #d1d6e1;

    .input {
      p {
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
      }

      &.email {
        margin-bottom: 28px;
      }

      &.password {
        margin-bottom: 16px;
      }
    }

    .forgot-password {
      margin-bottom: 16px;
      text-align: end;

      a {
        text-decoration: none;

        span {
          font-weight: 700;
        }
      }
    }
  }
}
