.event-register-accordion {
  .event-title {
    background: rgba(217, 217, 217, 0.18);
    border: 1px solid rgba(224, 228, 241, 0.92);
  }

  .event-content {
    padding: 16px;

    .event-info-label {
      padding: 8px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    .event-info-text {
      padding: 8px;
      width: 100%;
      box-sizing: border-box;

      pre {
        background-color: rgba(217, 217, 217, 0.25);
        padding: 16px;
        overflow-x: auto;
        white-space: pre;
        word-wrap: normal;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
