.product-spot-view {
  padding: 24px;

  .view-header {
    padding: 0 16px;
    margin-bottom: 16px;

    .view-title {
      width: fit-content;
      min-width: 100px;
      height: 24px;
    }
  }

  .details {
    padding: 0 16px;
    padding-top: 24px;

    .details-subtitle {
      margin-bottom: 16px;
      color: #008037;
    }

    .info-row {
      display: flex;
      margin-bottom: 24px;
    }

    .basic-info,
    .fiscal-info,
    .retention-info {
      margin-bottom: 40px;
    }
  }

  .view-tab {
    padding: 24px 16px 0;

    .general-subtitle {
      margin-bottom: 16px;
      color: #008037;
    }

    .info-row {
      display: flex;
      margin-bottom: 24px;

      & > div {
        padding-right: 20px;
        box-sizing: border-box;
      }

      .open-new-tab {
        padding-top: 10px;
      }
    }

    .link-row {
      display: block;
    }

    .segment-info {
      margin-bottom: 40px;
    }
  }
}




//.plan-view {
//  padding: 24px;
//
//  .view-header {
//    padding: 0 16px;
//    margin-bottom: 16px;
//
//    .view-title {
//      width: fit-content;
//      min-width: 100px;
//      height: 24px;
//    }
//  }
//
//
//}
