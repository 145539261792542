.configuration-item {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: unset;

  padding: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 12px;

  &:hover {
    background-color: rgb(247, 247, 247);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
      rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  }

  .icon {
    display: flex;
    align-items: center;
  }

  .text {
    .title {
      margin-bottom: 4px;
    }
  }
}
