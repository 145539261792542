.summary-step {
  padding: 24px;

  .summary-subtitle {
    margin-bottom: 24px;
  }

  .info-row {
    margin-bottom: 24px;
  }
}