.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  .overflow-hidden{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-file {
      max-height: 150px;
      width: 100%;
      object-fit: contain;
    }
  }

  .edit-button{
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(30%, 25%);
    min-width: unset;
    padding: 4px;
    border-radius: 100%;
    z-index: 2;
  }
}