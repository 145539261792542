@import "./fonts";

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Satoshi";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}