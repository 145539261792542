.custom-modal {
  &.create-tag-modal {
    width: unset;

    .modal-body {
      width: 480px;
      padding: 16px 24px;
      border-bottom: 1px solid #d1d6e1;

      .tag-name {
        margin-bottom: 25px;
      }

      .text-input {
        p {
          position: absolute;
          bottom: 0;
          transform: translateY(100%);
        }
      }
    }
  }
}