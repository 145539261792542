.sidebar-menu {
  background-color: #004a36;
  padding: 32px;
  height: 100vh;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  .logo {
    width: 150px;
    margin-bottom: 60px;

    img {
      width: 100%;
    }
  }

  .item-list {
    height: 100%;
  }

  .configurations {
    display: flex;
    color: white;

    .avatar {
      margin-top: 10px;
    }

    .profile {
      width: 100%;
      padding: 0px 10px;
      max-width: 130px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .email {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .logout-container {
        width: fit-content;

        .logout-text {
          text-decoration: underline;
          cursor: pointer;
        }

        .version-text {
          margin-top: 30px;
          font-size: 12px;
        }
      }
    }

    .icon {
      margin-top: 5px;
    }
  }

}