.api-error-view {
  align-items: center;
  padding: 50px 20px;
  width: 100%;
  box-sizing: border-box;
  min-height: 80px;
  text-align: center;

  .title {
    margin-bottom: 12px;
    color: #8691a6;
  }
}
