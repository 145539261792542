.totals-card {
  align-items: flex-start;
  padding: 11px 16px;
  gap: 8px;
  min-width: 237px;
  width: fit-content;
  min-height: 80px;
  background: #ffffff;
  border: 1px solid #d1d6e1;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;

  .title {
    margin-bottom: 12px;
    color: #4c5568;
  }

  .icon {
    position: absolute;
    top: 12px;
    right: 16px;
  }

  .content {
    margin-bottom: 12px;
    color: #4c5568;
  }

  .caption {
    color: #4c5568;
  }
}