.table-row {
  &.linked-table-row:hover {
    cursor: pointer;
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
      0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    z-index: 2;
  }

  .checkbox-cell {
    padding: 0 0 0 4px;
    width: 48px;

    a.table-cell-content {
      display: flex;
      align-items: center;
      height: 55px;
    }
  }

  .table-cell {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;

    &.big {
      max-width: 170px;
    }

    &.medium {
      width: 150px;
      max-width: 150px;
    }

    &.small {
      width: 120px;
      max-width: 120px;
    }

    .table-cell-content {
      display: block;
      padding: 16px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      text-decoration: none;
      color: #393939;
    }

    .ellipsis-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-status {
      border: 1px solid;
      border-radius: 6px;
      flex-direction: row;
      align-items: flex-start;
      padding: 3px 16px;
      gap: 4px;
    }

    .label-status-blue {
      background: #d6e0fe;
      border-color: #1a2eaf;
      color: #1a2eaf;
    }

    .label-status-green {
      background: #c6f8c8;
      border-color: #006e3a;
      color: #006e3a;
    }

    .label-status-red {
      background: #fccfcf;
      border-color: #af105f;
      color: #af105f;
    }

    .label-status-yellow {
      background: #fff0ce;
      border-color: #b74c05;
      color: #b74c05;
    }

    .label-tag-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 4px;
      max-width: 80%;
      overflow: hidden;

      .label-tag {
        background: #ffffff;
        border: 1px solid #d1d6e1;
        border-radius: 6px;
        padding: 3px 16px;
        gap: 4px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .label-column {
      display: flex;

      .label-tag-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;
        max-width: 80%;
        overflow: hidden;

        .label-tag {
          background: #ffffff;
          border: 1px solid #d1d6e1;
          border-radius: 6px;
          padding: 3px 16px;
          gap: 4px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .label-tag-container-plus {
        min-width: 20%;
        display: flex;
        margin-left: 4px;

        .label-tag {
          cursor: pointer;
          background: #ffffff;
          border: 1px solid #d1d6e1;
          border-radius: 6px;
          padding: 3px 5px;
          text-align: center;
        }
      }
    }

    &.menu-options-cell {
      width: 45px;
      .table-cell-content {
        padding: 0;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
