.section-title-final {
  text-align: center;
}

.section-progress {
  margin: 20px;

  .progress-bar {
    margin: 20px 0
  }
}

.error-description-section {
  margin: 50px;
  text-align: center;
}

.payment-description-section {
  margin: 50px 20px 0 20px;
  text-align: center;

  .button {
    margin: 20px;
  }

  .qr-code-container {
    .qr-code {
      margin: 50px;
    }
  }
}