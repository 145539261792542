
.review-view {

    padding: 24px;
    .info-row {
        margin-bottom: 24px;
    }

    .multiline-space {
        margin-bottom: 12px;
    }

    .title-row {
        margin-bottom: 40px;
    }

    .subtitle-row {
        margin-bottom: 8px;
    }

    .row-check-box-container {
        display: grid;

        .row-check-box {
            display: inline-flex;

            .link {
                padding-top: 10px
            }
        }
    }

    .total-section {
        margin: 20px;
        display: grid;
        justify-content: end;
  
    .row {
        text-align: end;
        font-weight: 700;
    }
  }
}