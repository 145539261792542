.home-page {
  padding: 10px 40px;

  .welcome {
    margin-bottom: 24px;
  }

  .home-section {
    padding: 30px;

    .title {
      margin-bottom: 20px;
      color: #008037;
    }

    .card {
      width: 100%;
      color: #008037;
    }
  }
}
