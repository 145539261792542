.accounts-receivables-view {
  padding: 24px;

  .view-header {
    padding: 0 16px;
    margin-bottom: 16px;

    .view-title {
      width: fit-content;
      min-width: 100px;
      height: 24px;
    }
  }

  .details {
    padding: 0 16px;
    padding-top: 24px;

    .details-subtitle {
      margin-bottom: 16px;
      color: #008037;
    }

    .info-row {
      display: flex;
      margin-bottom: 24px;

      & > div {
        padding-right: 20px;
        box-sizing: border-box;

        &:last-child {
          padding: 0;
        }
      }
    }

    .segment-info {
      margin-bottom: 40px;
    }
  }
}
