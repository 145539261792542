.accounts-receivables-page {
  .totals-container {
    .totals-card {
      min-width: 330px;
    }

    .total-icon {
      width: 28px;
      height: 28px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.expired-icon {
        background-color: #fccfcf;
        stroke: #af105f;
      }
      &.paid-icon {
        background-color: #f2f4f9;
        stroke: #4c5568;
      }
      &.pending-icon {
        background-color: #c6f8c8;
        stroke: #008037;
      }
    }
  }
}
