.forgot-password-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    margin-bottom: 28px;
    width: 200px;
    height: fit-content;

    img {
      width: 100%;
    }
  }

  .form-box {
    padding: 40px 24px;
    width: 420px;
    border: 1px solid #d1d6e1;

    .text-container {
      margin-bottom: 24px;

      .title {
        margin-bottom: 4px;
      }
    }

    .form-container {
      .text-input {
        margin-bottom: 28px;

        p {
          position: absolute;
          bottom: 0;
          transform: translateY(100%);
        }
      }

      .buttons-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
