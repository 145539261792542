.collapse-menu {
  margin-bottom: 8px;

  .accordion {
    background-color: #004a36;
    box-shadow: none;

    .accordion-summary {
      display: flex;
      padding: 8px 12px;
      cursor: pointer;
      border-radius: 8px;
      justify-content: flex-start;
      align-items: center;
      min-height: unset;

      &:hover {
        background-color: #005c39;
      }

      div {
        margin: 0;
      }

      svg {
        color: white;
      }

      .accordion-summary-text {
        margin-left: 12px;
      }
    }

    .items {
      padding: 0;

      .menu-subitem {
        color: white;
        padding: 8px 12px;
        padding-left: 42px;
        margin-bottom: 8px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: #005c39;
        }

        &:first-child {
          margin-top: 8px;
        }

        &.selected {
          background-color: #005c39;

          &::after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: white;
            border-radius: 100%;

            position: absolute;
            top: 50%;
            left: 18px;

            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
