.customer-type-container {
  margin: 20px;
}

.section-title {
  margin: 30px 20px 0;
}

.captcha {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}