.listing-page {
  padding: 8px;

  .action-header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 32px 0;

    .title {
      font-weight: 700;
      font-size: 28px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .action-button {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
      }
    }
  }

  .totals-container {
    display: flex;
    width: 100%;
    gap: 16px;
  }

  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 16px 0;
    height: 37px;

    .search {
      width: 345px;
    }

    .filters {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .filter {
        position: relative;

        .buttons-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .bulk-actions-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 16px 0;
    height: 37px;
  }
}

.filter-modal {
  padding: 10px;
  min-width: 250px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.bulk-action-modal {
  z-index: 100;
  min-width: 250px;
  border-radius: 8px;
  height: fit-content;

  .options {
    max-height: 500px;
    overflow-y: auto;
    padding: 0 10px;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #d1d6e1;
  }
}
