.product-name-section {
  margin: 20px;
}

.error-section {
  margin: 20px;
  text-align: center;
}

.product-description-section {
  margin: 20px;
}

.coupon-section {
  display: grid;

  .title {
    margin: 0 20px;
  }

  .input-container {
    display: inline-flex;
    float: left;

    .button {
      margin: 20px 0;
      height: 50px;
    }
  }


}

.total-section {
  margin: 20px;
  display: grid;
  justify-content: end;

  .row {
    text-align: end;
    font-weight: 700;
  }
}