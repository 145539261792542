.custom-modal {
  width: 700px;

  .modal-header {
    border-bottom: 1px solid #d1d6e1;
    padding: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-close-button {
      height: 24px;
      border-radius: 100%;
      padding: 6px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 128, 55, 0.04);
      }
    }
  }

  .modal-body {
    overflow-y: auto;
    max-height: 75vh;

    .section-title {
      color: #008037;
      padding: 0 16px;
      margin-top: 24px;
    }

    .checkbox-container {
      padding: 0 16px;
    }

    .error-message {
      color: #af105f;
      font-size: 10pt;
    }

    .attachment-list {
      display: block;

      .attachment-item {
        display: flex;

        .detail-attachment {
          margin-bottom: 0px;
        }
      }

      .detail-attachment .detail-url .fixed-width {
        width: 350px;
      }
    }
  }

  .modal-footer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px;
    border-top: 1px solid #d1d6e1;

    .loading {
      margin-top: 7px;
    }
  }
}
