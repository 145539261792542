.installment-title {
  padding: 5px 18px;
}

.installment-list {

  .installment-item {
    padding: 5px 18px;
  }
}

.new-installment-container {
  display: flex;

  .remove-button {
    height: 40px;
    margin: 25px 0px;
  }
}

.add-button {
  padding: 5px 10px;
}
