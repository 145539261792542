.flex-container {
  width: 100%;
  height: 100vh;
  display: flex;

  .sidebar-container {
    width: 280px;
    height: 100vh;
  }

  .page-container {
    width: calc(100vw - 280px);
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
  }
}
