.checkout-page {
  display: inline-block;
  position: relative;
  left: 25%;
  width: 50%;
  margin-top: 20px;

  .header {
    position: relative;
    height: 100px;
    width: 100%;
    left: 33%;

    .logo {
      display: inline-block;
      height: 100px;
      width: 100px;
      padding-right: 20px;

      .image {
        height: 100px;
        width: 100px;
      }
    }

    .loading {
      display: inline-block;
      height: 100px;
    }

    .divisor {
      border-right-style: solid;
      border-color: gray;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 60px;
    }

    .logo-description {
      margin: 0 0 0 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 10px;
    }
  }

  .sub-header {
    text-align: center;
  }

  .checkout-body {
    margin: 40px 0;
    padding: 30px 10px;

    .steps {
      margin-bottom: 50px;
    }
  }

  .footer {
    margin: 30px 20px 0;
    gap: 15px;
    display: flex;
    justify-content: flex-end;
  }
}