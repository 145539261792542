.display-info-text {
  .title {
    color: #8691a6;
    margin-bottom: 4px;
  }

  .container-text {
    display: flex;
    cursor: pointer;
    height: 0px;

    a:link, a:hover, a:visited, a:visited {
      text-decoration: none;
      color: inherit
    }

    .hide-icon {
      display: none;
    }

    .text:hover+.hide-icon {
      display: block;
    }

    .icon {
      padding: 0px 3px;
      color: #8691a6;
    }

  }
}