.display-info-copy {

  .title {
    color: #8691a6;
    margin-bottom: 4px;
  }

  .text-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .skeleton-loading {
      width: 100px;
    }
    .copy-icon {
      color: #747b95;
      cursor: pointer;
    }
  }
}
