.terms-of-use {
  .modal-body {
    padding: 18px;

    .read-terms {
      margin-bottom: 18px;
    }

    iframe {
      width: 100%;
      height: 400px;
      box-shadow: none;
      border: 1px solid #d1d6e1;
      border-radius: 5px;
    }
  }
}