.config-step {
  .radio-input-container {
    padding: 0 18px;
  }

  .field-button {
    display: flex;
    align-items: center;
  }

  .config-subtitle-first {
    margin-top: 12px;
    margin-bottom: 24px;
    padding-left: 20px;
  }

  .config-subtitle {
    margin-top: 32px;
    margin-bottom: 24px;
    padding-left: 20px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }
}