.configuration-system {
  padding: 8px 24px;

  .configuration-title {
    margin: 32px 0;
  }

  .config {
    margin-bottom: 24px;

    .config-label {
      margin-bottom: 8px;

      &.cco-config {
        margin-bottom: 16px;
      }

      .section-title {
        color: #008037;
      }

      .section-subtitle {
        color: #8691a6;
      }
    }

    .text-field-container {
      width: 400px;

      .config-loading {
        span {
          height: 52px;
        }
      }
    }

    .config-loading {
      width: 400px;
      span {
        height: 43px;
      }
    }
  }

  .config-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}
