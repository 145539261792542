.table-row {
  .table-cell {
    .icon-link {
      &.red-icon {
        svg {
          stroke: #e04a77;
        }
      }

      &.disabled-icon {
        svg {
          stroke: #d6e0fe;
        }
      }
    }
  }
}
