.detail-attachment {
  margin-bottom: 20px;
  .detail-description {
  }

  .detail-url {
    display: flex;
    align-items: center;
    gap: 8px;

    .skeleton-loading {
      width: 100px;
    }

    .url {
      color: #8691a6;
      margin-bottom: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .fixed-width {
      width: 130px;
    }

    .copy-link-icon {
      color: #747b95;
      cursor: pointer;
    }
  }
}
