.credit-card-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    margin-bottom: 28px;
    width: 200px;
    height: fit-content;

    img {
      width: 100%;
    }
  }

  .company-name {
    padding: 15px;
  }

  .charge-details {
    padding: 15px;
    display: block;
    gap: 4px;
    font-size: 16px;
    
    .value {
      color: #008037;
      font-weight: 700;
    }
  }

  .form-box {
    width: 500px;
    border: 1px solid #d1d6e1;

    .text-container {
      padding: 12px 24px;
    }

    .hide-input-controls {

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
        // -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
      }
    }
  }

  .security-container {
    padding: 15px;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
}