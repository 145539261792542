.range-filter-modal {
  padding: 10px;
  width: 250px;

  .slider {
    width: 92%;
    padding: 0px 10px;
  }

  .range-container {
    display: flex;

    .range-divisor {
      padding: 5% 2%;
      width: 5%;
    }

    .range-text {
      width: 45%;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }
}