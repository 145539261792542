.empty-view {
  align-items: center;
  padding: 50px 20px;
  width: 100%;
  min-height: 80px;
  text-align: center;

  .title {
    margin-bottom: 12px;
    color: #8691a6;
  }
}
