$base-width: 8.33%;

.flexbox-container {
  display: flex;

  &.column {
    flex-direction: column;
  }

  .field {
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .helper-text {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
    }
  }
}

.size-1 {
  width: $base-width;
}

.size-2 {
  width: calc(2 * $base-width);
}

.size-3 {
  width: calc(3 * $base-width);
}

.size-4 {
  width: calc(4 * $base-width);
}

.size-5 {
  width: calc(5 * $base-width);
}

.size-6 {
  width: calc(6 * $base-width);
}

.size-7 {
  width: calc(7 * $base-width);
}

.size-8 {
  width: calc(8 * $base-width);
}

.size-9 {
  width: calc(9 * $base-width);
}

.size-10 {
  width: calc(10 * $base-width);
}

.size-11 {
  width: calc(11 * $base-width);
}

.size-12 {
  width: calc(12 * $base-width);
}