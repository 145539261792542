.menu-item {
  display: flex;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 8px;
  background-color: #004a36;

  &:hover {
    background-color: #005c39;
  }

  .menu-title {
    margin-left: 12px;
    color: white;
  }
}
