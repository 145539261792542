.display-info-link {

  .title {
    color: #8691a6;
    margin-bottom: 4px;
  }

  .link-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .skeleton-loading {
      width: 100px;
    }

    .link {
      color: #8691a6;
      margin-bottom: 4px;
      width: 130px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .copy-link-icon {
      color: #747b95;
      cursor: pointer;
    }
  }
}
