.configuration-company {
  padding: 8px;

  .configuration-title {
    margin: 32px 0;
    padding: 0 16px;
  }

  .section-title {
    color: #008037;
    padding: 0 16px;
    margin-top: 24px;
  }

  .config-logo-container {
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .config-logo {
      max-height: 150px;
    }
  }

  .config-buttons-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 0 16px;
    margin-top: 12px;
  }
}
